/* eslint-disable react/no-danger */
import React from 'react'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import PropTypes from 'prop-types'
import Flag from 'react-flagkit'
import { useI18next } from 'gatsby-plugin-react-i18next'

const LanguageSwitcher = ({ translations }) => {
  const { language } = useI18next()

  const fallback = language === 'sv' ? '/en/' : '/'

  const link = typeof translations !== 'undefined' && translations[0] ? translations[0].link : fallback

  const Button = styled.button`
    ${tw``}
  `
  const Language = styled(props => <Flag {...props} />)`
    ${tw`border border-white translate-y-1`}
  `

  return (
    <Button
      type="button"
      onClick={() => {
        navigate(link)
      }}
    >
      {language === 'sv' ? <Language country="GB" /> : <Language country="SE" />}
    </Button>
  )
}

LanguageSwitcher.propTypes = {
  translations: PropTypes.array,
  link: PropTypes.string,
}

export default LanguageSwitcher
