import React from 'react'
import { Global, css } from '@emotion/react'
import tw, { GlobalStyles as BaseStyles } from 'twin.macro'

const customStyles = css({
  html: {
    ...tw`font-body bg-primary antialiased`,
  },
  body: {
    ...tw`font-body bg-offWhite`,
  },
  h1: {
    ...tw`text-4xl`,
  },
  h2: {
    ...tw`text-3xl`,
  },
  h3: {
    ...tw`text-2xl`,
  },
  'h1, h2, h3, h4, h5': {
    ...tw`font-display`,
  },
  p: {
    ...tw`font-body font-normal`,
  },
  '.button': {
    ...tw`border py-4 px-10 font-bold`,
  },
  '.button-primary': {
    ...tw`text-black hover:text-white mt-3 ml-3 border border-black hover:bg-black`,
  },
  '.button-secondary': {
    ...tw`text-white hover:text-black mt-3 ml-3 border border-white hover:bg-white`,
  },
})

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={customStyles} />
  </>
)

export default GlobalStyles
