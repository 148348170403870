import { useStaticQuery, graphql, Link } from 'gatsby'
// import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import PropTypes from 'prop-types'
import OffCanvas from 'react-aria-offcanvas'
import Hamburger from 'hamburger-react'
import { useI18next } from 'gatsby-plugin-react-i18next'
import OffCanvasMenu from './OffCanvasMenu'
import flatListToHierarchical from '../utils/flatListToHierarchical'
import HeaderMenu from './Header/HeaderMenu'
import RedLogo from '../assets/images/red-red.svg'
import WhiteLogo from '../assets/images/red-white.svg'

const Header = ({ isFrontPage, translations }) => {
  const {
    en: { nodes: en },
    sv: { nodes: sv },
  } = useStaticQuery(graphql`
    query HEADER_MENU_ITEMS {
      en: allWpMenuItem(filter: { locations: { eq: GATSBY_HEADER_MENU___EN } }, sort: { fields: order }) {
        nodes {
          ...MenuItem
        }
      }
      sv: allWpMenuItem(filter: { locations: { eq: GATSBY_HEADER_MENU } }, sort: { fields: order }) {
        nodes {
          ...MenuItem
        }
      }
    }
  `)

  const Wrapper = styled.header`
    ${isFrontPage ? tw`absolute left-0 top-0 w-full z-40 bg-transparent` : tw`bg-offWhite`};
  `
  const Nav = styled.nav`
    ${tw`max-w-screen-3xl mx-auto pl-8 pr-6 xl:pr-8`}
  `
  const Container = styled.nav`
    ${tw`w-full pt-8 lg:pt-0 pb-0 lg:pb-8 flex items-center justify-between`}
  `
  const ButtonGroup = styled.div`
    ${tw`ml-10 space-x-4 items-center inline-block xl:hidden mt-1 md:mt-0 lg:mt-6 xl:mt-4 2xl:mt-0`}
  `
  const BurgerButton = styled.div`
    ${tw``}
  `
  const bgStyle = {
    background: '#A01929',
    width: '80vw',
    zIndex: '200',
    maxWidth: 400,
  }
  const overlayStyle = {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: '100',
  }

  const { language } = useI18next()

  const menuItems = flatListToHierarchical(language === 'en' ? en : sv)

  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <Wrapper>
        <Nav aria-label="Top">
          <Container>
            <Link to="/" className="pt-0 lg:pt-7">
              {isFrontPage ? <WhiteLogo className="h-8 md:h-12 w-auto" /> : <RedLogo className="h-8 md:h-12 w-auto" />}
            </Link>
            <div className={`${isFrontPage ? 'text-white' : 'text-ricoDarkGray'}`}>
              <HeaderMenu menu={menuItems} isFrontPage={isFrontPage} translations={translations} />
            </div>
            <ButtonGroup>
              <BurgerButton>
                <Hamburger
                  toggled={isOpen}
                  toggle={setOpen}
                  size={25}
                  color={`${isFrontPage ? 'white' : '#A01929'}`}
                  rounded
                />
              </BurgerButton>
            </ButtonGroup>
          </Container>
        </Nav>
      </Wrapper>
      <OffCanvas
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        position="right"
        labelledby="menu-button"
        height="100vh"
        style={{ content: bgStyle, overlay: overlayStyle }}
      >
        <OffCanvasMenu toggle={() => setOpen(!isOpen)} menu={menuItems} translations={translations} />
      </OffCanvas>
    </>
  )
}

Header.propTypes = {
  isFrontPage: PropTypes.bool,
  translations: PropTypes.array,
}

export default Header
