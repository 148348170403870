/* eslint-disable react/no-danger */
import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router' // this helps tracking the location
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import sanitizeHtml from 'sanitize-html'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

const CookieConsentPopup = () => {
  const data = useStaticQuery(graphql`
    query CookieConsentPopup {
      wp {
        globalSettings {
          globalSettings {
            en: cookieConsentEnglish {
              link {
                url
                title
              }
              text
            }
            sv: cookieConsentSwedish {
              text
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const { language } = useI18next()

  const { t } = useTranslation()

  const { globalSettings } = data.wp.globalSettings

  const content = language === 'en' ? globalSettings.en : globalSettings.sv

  const location = useLocation()
  return (
    <CookieConsent
      disableStyles
      enableDeclineButton
      buttonId="accept-cookie"
      containerClasses="fixed bottom-0 right-0 w-full md:mr-5 md:mb-5 md:max-w-md p-6 bg-primary bg-opacity-75 backdrop-blur-md z-50"
      contentClasses="text-white text-xs md:text-base"
      declineButtonClasses="text-white font-medium text-sm"
      buttonClasses="bg-white px-5 py-2 text-primary font-medium mt-3 ml-3"
      declineButtonText={t('Avvisa')}
      buttonText={t('Godkänn')}
      cookieName="gatsby-gdpr-google-tagmanager"
      onAccept={() => {
        initializeAndTrack(location)
      }}
    >
      <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(content.text) }} />
      <Link
        to={content.link.url}
        className="underline"
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(content.link.title) }}
      />
    </CookieConsent>
  )
}

export default CookieConsentPopup
