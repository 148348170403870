import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import * as sH from 'sanitize-html'
import tw from 'twin.macro'
import { useSwipeable } from 'react-swipeable'
import { Link } from 'gatsby'
import LanguageSwitcher from './LanguageSwitcher'

const OffCanvasMenu = ({ toggle, menu, translations } = {}) => {
  const Wrapper = styled.nav`
    ${tw`mb-20 p-10`}
  `
  const Menu = styled.ul`
    ${tw`font-medium text-white`}
    button {
      ${tw`p-4 mt-5`}
    }
  `
  const MenuItemWrapper = styled.li``

  const MenuItem = styled(props => <Link {...props} />)`
    ${tw`block p-4 text-base font-medium text-white hover:text-indigo-50`}
  `
  const handlers = useSwipeable({
    onSwipedRight: () => toggle(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  return (
    <div className="h-full" {...handlers}>
      <Wrapper id="menu" aria-label="Sidebar">
        <Menu>
          {menu?.map(({ key, url, title } = {}) => (
            <MenuItemWrapper key={key}>
              <MenuItem to={url} dangerouslySetInnerHTML={{ __html: sH(title) }} />
            </MenuItemWrapper>
          ))}
          {translations && <LanguageSwitcher translations={translations} />}
        </Menu>
      </Wrapper>
    </div>
  )
}

OffCanvasMenu.propTypes = {
  menu: PropTypes.array.isRequired,
  translations: PropTypes.array,
  toggle: PropTypes.func,
}

export default OffCanvasMenu
