/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import Header from './Header'
import GlobalStyles from '../styles/GlobalStyles'
import CookieConsentPopup from './CookieConsent'
import R from '../assets/images/r.svg'
import Rinv from '../assets/images/r-inv.svg'

import '@fortawesome/fontawesome-svg-core/styles.css'

export const Footer = loadable(() => pMinDelay(import('./Footer/Footer'), 500))

const Layout = ({ children, isFrontPage, translations }) => {
  const { site: { siteMetadata: { title: siteTitle } = {} } = {} } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const Watermarks = styled.div`
    ${tw`w-72 md:transform lg:-translate-x-1/2 absolute lg:w-[450px]`}
    ${isFrontPage
      ? tw`!z-40 top-24 md:top-[15vw] lg:top-[5vw] xl:top-[200px] lg:w-[500px]`
      : tw`top-[-150px] lg:top-[-70px] `};
  `
  return (
    <ParallaxProvider>
      {isFrontPage ? (
        <Watermarks className="z-50 -left-1/4 md:left-[-100px] lg:left-[calc(50%-700px)]">
          <Rinv className="w-full h-full" />
        </Watermarks>
      ) : (
        <Parallax speed={-20}>
          <Watermarks className="-z-0 -left-1/4 md:left-[-100px] lg:left-[calc(50%-675px)]">
            <R className="w-full h-full" />
          </Watermarks>
        </Parallax>
      )}
      <GlobalStyles />
      <Header isFrontPage={isFrontPage} siteTitle={siteTitle} translations={translations} />
      <main>{children}</main>
      <Footer />
      <CookieConsentPopup siteTitle={siteTitle} />
    </ParallaxProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isFrontPage: PropTypes.bool,
  translations: PropTypes.array,
}

export default Layout
