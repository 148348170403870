/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types'

const NavButton = ({ direction, swiperObj, off, children }) => {
  const disabled = '!bg-transparent !border-white !opacity-50 !cursor-default'

  const slide = dir => {
    dir === 'Next'
      ? swiperObj.current.swiper.slideNext()
      : direction === 'Previous'
      ? swiperObj.current.swiper.slidePrev()
      : ''
  }

  return (
    <button
      type="button"
      onClick={() => {
        slide(direction)
      }}
      aria-label={direction}
      className={`cursor-pointer bg-primary border-primary opacity-100 border text-sm w-10 h-10 ml-2 flex items-center justify-center transition-all ease-in-out duration-300 ${
        off ? disabled : ''
      }`}
    >
      {children}
    </button>
  )
}

NavButton.propTypes = {
  direction: PropTypes.string,
  off: PropTypes.bool,
  swiperObj: PropTypes.object,
  children: PropTypes.object,
}

NavButton.defaultProps = {}

export default NavButton
