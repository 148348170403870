import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import * as sH from 'sanitize-html'
import LanguageSwitcher from '../LanguageSwitcher'

const HeaderMenu = ({ menu, isFrontPage, translations }) => {
  const Menu = styled.div`
    ${tw`hidden ml-10 xl:space-x-3 xl:block`}
    >a {
      ${tw`inline-block px-7 pb-5 pt-12 text-base font-medium`}
    }
    .ac {
      ${isFrontPage ? tw`bg-ricoDarkGray text-white` : tw`bg-primary text-white`};
      &:first-of-type {
        ${isFrontPage ? tw`bg-ricoDarkGray text-white` : tw`bg-transparent text-ricoDarkGray`};
      }
    }
  `

  return (
    <Menu>
      {menu?.map(({ key, url, title }) => (
        <Link activeClassName="ac" partiallyActive key={key} to={url} dangerouslySetInnerHTML={{ __html: sH(title) }} />
      ))}
      <a>
        <LanguageSwitcher translations={translations} />
      </a>
    </Menu>
  )
}

HeaderMenu.propTypes = {
  menu: PropTypes.array.isRequired,
  isFrontPage: PropTypes.bool,
  translations: PropTypes.array,
}

export default HeaderMenu
